<template>
  <div>
    <img
      src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/static/kejian_future.png"
    />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
img{
    width: 100%;
}
</style>